<template>
  <div class="personal-information">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link> 
      <span> >  </span>
      <span style="color:#3461FF">{{ $t('profile.Information') }}</span>
    </div>

    <!-- <el-card>
      <template #header>Information</template>
    </el-card> -->
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" type="border-card">
      <el-tab-pane :label="$t('profile.Information')" name="first">
        <div class="form-box">
          <el-form
            ref="form" 
            label-width="180px"
            style="width: 500px"
            :model="information"
            size="small"
          >
            <el-form-item :label="$t('profile.Account')">
              <el-input v-model="information.loginId" disabled />
            </el-form-item>
            <el-form-item :label="$t('profile.Groups')">
              <el-input v-model="information.userRoleName" disabled />
            </el-form-item>
            <el-form-item :label="$t('profile.Uers Name')" prop="name">
              <el-input 
                v-model="information.userName" 
                :placeholder="$t('profile.Uers Name')"
                clearable
              />
            </el-form-item>
            <el-form-item :label="$t('profile.First Name')" prop="firstName">
              <el-input 
                v-model="information.firstName" 
                :placeholder="$t('profile.First Name')"
                clearable
              />
            </el-form-item>
            <el-form-item :label="$t('profile.Last Name')" prop="lastName">
              <el-input 
                v-model="information.lastName" 
                :placeholder="$t('profile.Last Name')"
                clearable
              />
            </el-form-item>
            <el-form-item  :label="$t('profile.Department')" prop="department">
              <el-input 
                v-model="information.department" 
                :placeholder="$t('profile.Department')"
                clearable
              />
            </el-form-item>
            <el-form-item :label="$t('profile.Phone')">
              <!-- <el-input
                type="number" 
                v-model="information.phone" 
                placeholder="phone"
              /> -->
              <div>{{information.phone}}</div>
            </el-form-item>
            <el-form-item :label="$t('profile.Language')">
              <el-select v-model="information.languageId"  placeholder="">
                <el-option
                  v-for="item in languageList"
                  :key="item.languageId"
                  :label="item.name"
                  :value="item.languageId"
                />
              </el-select>
            </el-form-item>

            <!-- 保存按钮 -->
            <el-form-item>
              <el-button 
                color="#28C76F" 
                type="success"
                @click="submitForm"
              >{{$t('button.Save')}}</el-button>
            </el-form-item>
          
          </el-form>
        </div>
      </el-tab-pane>

      <!-- 修改密码tab -->
      <el-tab-pane :label="$t('profile.Change Password')" name="second">
        <div class="form-box password-box">
          <el-form 
            label-width="180px"
            style="width: 500px"
            :model="formPassword"
            ref="passwordForm" 
            :rules="rules"
            size="small"
          >
            <el-form-item :label="$t('profile.Password')" prop="oldPassword">
              <el-input
                v-model="formPassword.oldPassword"
                type="password"
                :placeholder="$t('profile.Password')"
                show-password
              >
                <template #prefix>
                  <span><i class="el-icon-lock"></i></span>
                </template>
                <!-- <template #prefix>
                  <span><img class="form-icon" src="../../assets/login/pass.png" /></span>
                </template> -->
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('profile.New Password')" prop="newPassword">
              <el-input
                v-model="formPassword.newPassword"
                type="password"
                :placeholder="$t('profile.New Password')"
                show-password
              >
                <template #prefix>
                  <span><i class="el-icon-lock"></i></span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('profile.Confirm New Password')" prop="confirmNewPassword">
              <el-input
                v-model="formPassword.confirmNewPassword"
                type="password"
                :placeholder="$t('profile.Confirm New Password')"
                show-password
              >
                <template #prefix>
                  <span><i class="el-icon-lock"></i></span>
                </template>
              </el-input>
            </el-form-item>

            <!-- 保存按钮 -->
            <el-form-item>
              <el-button 
                color="#28C76F" 
                type="success"
                @click="submitFormPasswd"
              >{{$t('button.Save')}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import { getUser, ownerInfoUpdate, updatePassword } from "@/libs/api/user";
  import { languageGetList } from "@/libs/api/system-management-customer";
  import { checkIsPasswordRegex } from "@/utils/common";
  import md5 from 'js-md5'

  let loading;
  export default {
    name:'personalInformation',
    components: {
    },
    props: {},
    data() {
      return {
        languageList:[],
        activeName:'first',
        information:{
          loginId:'1394944994@qq.com',
          userName:'zhao',
          firstName:'三',
          lastName:'张',
          department:'IT',
          phone:'18302987465',
          languageId:'5',
          startPage:'Index',
          userRoleId:'',//用户分组ID
          userRoleName:'',//用户分组名称
        },
        formPassword:{
          oldPassword:'',
          newPassword:'',
          confirmNewPassword:''
        },
        rules:{
          oldPassword: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkOld, trigger: 'blur' }
          ],
          newPassword: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkNew, trigger: 'blur' }
          ],
          confirmNewPassword: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkConfirm, trigger: 'blur' }
          ]
        }
      }
    },
    created(){
      loading = this.$loading({
        text:"loading...",
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.getData()
      this.getLanguageList()
    },
    mounted(){
      
    },
    methods: {
      // 获取用户信息
      async getData(){
        let userData = JSON.parse(localStorage.getItem('userData'))
        try {
          const res = await getUser({userId:userData.userId})
          let response = res.data
          if(response.state){
            this.information = response.data
            loading.close()
          } else{
            loading.close()
            this.$message({
              message: response.message,
              type:'warning',
            });
          }
        } catch (err) {
          loading.close()
          const { message} = err;
          this.$message.error(message)
        }
      },
      // 获取语言列表
      async getLanguageList () {
        let localData  = JSON.parse(localStorage.getItem("languageList"));
        if (localData && localData.languageList && localData.languageList.length > 0){
          // 从缓存获取数据
          this.languageList = localData.languageList;
        } else {
          // 服务端获取数据
          let { data } = await languageGetList();
          if(data.state){
            let list = data.data;
            list = list.map((value) => ({
              ...value,
              text:""
            }))
            localStorage.setItem("languageList", JSON.stringify({languageList: list}));
            this.languageList = list;
          } else {
            this.$message({
              message: data.message,
              type:'warning',
            });
          }
        } 
      },
      // 表单---个人信息校验
      submitForm(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.save()
      },
      // 保存个人信息
      async save(){
        let { phone,firstName,lastName,department,startPage,userName,userRoleId,languageId } = this.information
        let params = {
          phone,
          firstName,
          lastName,
          department,
          startPage,
          userName,
          userRoleId,
          languageId
        }
        try {
          const res = await ownerInfoUpdate(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.ModifySucess'),
              type:'success',
            });
            this.getData()
          } else{
            loading.close()
            this.$message({
              message: response.message,
              type:'warning',
            });
          }
        } catch (err) {
          loading.close()
          const { message} = err;
          this.$message.error(message)
        }
      },
      // 校验 ----旧密码
      checkOld(rule,value,callback){
        console.log('密码value',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            callback() 
          } else {
            return callback(new Error(this.$t('profile.FormError1')))
          }
        }
      },
      // 校验 --- 新密码
      checkNew(rule,value,callback){
        console.log('新密码密码',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            if (this.formPassword.confirmNewPassword !== '') {
              this.$refs.passwordForm.validateField('confirmNewPassword');
            }
            callback()
            // if(!this.formPassword.confirmNewPassword) return
            // if(value === this.formPassword.confirmNewPassword){
            //   callback()
            // } else {
            //   return callback(new Error('两次密码输入不一致！'))
            // }
          } else {
            return callback(new Error(this.$t('profile.FormError1')))
          }
        }
      },
      // 校验 --- 新密码确认
      checkConfirm(rule,value,callback){
        console.log('新密码--确认',value,rule)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
          // this.$t('formValidate.required fields')
          // loading.close()
        } else {
          if(value === this.formPassword.newPassword){
            callback()
          } else {
            return callback(new Error(this.$t('profile.FormError2')))
          }
        }
      },
      // 表单---重置密码校验
      submitFormPasswd(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.passwordForm.validate( valid => {
          if (valid) {
            console.log('密码表单校验通过',)
            this.updataApi()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 重置密码--请求api
      async updataApi(){
        let { oldPassword, newPassword} = this.formPassword
        let params = {
          oldPassword:md5(oldPassword),
          newPassword:md5(newPassword)
        }
        try {
          const res = await updatePassword(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('profile.Message1'),
              type:'success',
            });
            loading.close()
            this.$router.push({ //systemConfig/config
              name: 'auth-login'
            })
          } else{
            loading.close()
            this.$message({
              message: response.message,
              type:'warning',
            });
          }
        } catch (err) {
          loading.close()
          const { message} = err;
          this.$message.error(message)
        }
      },
      handleClick(){

      }
    }
  }
</script>
<style lang="scss" scoped>
  .personal-information{
    .form-box{
      width: 460px;
      margin: 0 auto;
      min-height: 600px;
      padding:  20px 0 0 0;
    }
    .password-box{
      ::v-deep .el-form-item{
        margin-bottom: 30px;
      }
    }
    .form-icon{
      display: inline-block;
      width: 16px;
    }

    // 重置样式
    .el-select{
      width: 100%;
    }
    .el-button--success{
      width: 100%;
    }
    ::v-deep .el-form-item__error{
      position: static;
    }
  }
</style>